.home-banner {
    position: relative;
    text-align: center;
    overflow: hidden;
    width: auto;
    background: url(../../../assets/images/home-banner.webp) no-repeat;
    background-size: cover;
    background-position: 35% 40%;
}

.home-banner-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: white;
    line-height: 1.5;

    background: rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 1rem;
}

.home-info-banner {
    background: url(../../images/closeup-excavator.webp) no-repeat;
    background-position: center;
    background-size: cover;
    width: auto;
    min-height: 600px;
}

.home-info {
    padding: 5%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.home-info .subtext {
    margin-top: 20px;
}

.home-info .subtext ul {
    margin: 25px;
    font-weight: 500;
    column-count: 3;
}

.home-more-info {
    padding: 20px;
    text-align: center;
    background: url(../../images/topology.webp) no-repeat;
    background-position: center;
    background-size: cover;
}

.home-more-info .cards {
    flex-direction: row;
    display: flex;
}

.home-more-info .card {
    background-color: white;
    border: black solid 2px;
    flex: 50%;
    margin: 8% 5%;
    padding: 2% 5%;
    box-shadow: -8px 8px 10px rgb(0 0 0 / 40%);
}

.home-more-info .card .card-title {
    margin-bottom: 20px;
}

.home-more-info .card .card-text {
    padding: 10px;
}

.home-more-info .contact {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    border: black solid 2px;
    box-shadow: -2px 2px 10px rgb(0 0 0 / 40%);
    display: inline-block;
    margin-bottom: 2%;
}

.home-more-info .contact a {
    text-decoration: none;
    color: black;
}


/* 

Responsive CSS

*/

@media only screen  and (max-width: 767px) {
    .home-banner {
        min-height: 45vh;
        font-size: 24px;
    }

    .home-info .subtext ul {
        column-count: 1;
    }

    .home-more-info .cards {
        flex-direction: column;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-banner {
        min-height: 60vh;
        font-size: 26px;
    }
    
    .home-info .subtext ul {
        column-count: 2;
    }
    
    .home-more-info .cards {
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px) {
    .home-banner {
        min-height: 75vh;
        font-size: 32px;
    }
}
