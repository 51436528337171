.footer-main {
    height: 100%;
    width: 100%;
    padding: 5% 0;
}

.footer-content {
    height: 100%;
}

.column {
    margin: 20px 0 10px 5%;
}