.horizontal-nav {
    min-height: 80px;
    width: 100vw;
    position: relative;
    overflow: hidden;
}

.nav-text {
    padding: 2% 3%;
}

.active a {
    border-bottom: solid black 3px;
}

.nav-text a {
    position: relative;
    top: 55%;
}

.horizontal-nav .logo {
    max-width: 80%;
    height: auto;
    max-height: 230px;
}

.horizontal-ul {
    display: flex;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
  
.horizontal-li {
    display: block;
    flex: 0 1 auto;
    list-style-type: none;
}
  
.horizontal-li a {
    display: block;
    text-align: center;
    padding: 14px 0px 10px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}

  