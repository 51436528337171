.contact-button {
    position: absolute;
    top: 2%;
    right: 5%;
    font-size: 18px;
    color: black;
    border: 2px solid;
    cursor: pointer;
}

.contact-button p {
    padding: 0px 20px;
    display: inline-block;
}

.contact-button i {
    padding: 10px 0 10px 20px;
    display: inline-block;
}


/* 

Responsive Navbar to switch between horizontal and vertical menus

*/
@media only screen and (max-width: 600px) {
    .horizontal-menu {
        display: none;
    }
    .contact-button {
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
    .horizontal-menu {
        display: none;
    }
    .contact-button {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .horizontal-menu {
        display: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vertical-menu {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .vertical-menu {
        display: none;
    }
}