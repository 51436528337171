
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.primary-bg {
  background-color: #9A9B73;
}

.secondary-bg {
  background-color: #C6B38E;
}

.primary-accent-bg {
  background-color: #3D0814;
}

.secondary-accent-bg {
  background-color: #E7F9A9;
}

.black-color {
  color: black;
}

.white-color {
  color: white;
}

.font-bold {
  font-weight: 600;
}

.font-body {
  font-size: 1.1em;
}

.font-header {
  font-size: 1.5em;
}




/* 

Responsive CSS

*/

@media only screen  and (max-width: 767px) {
  .font-body {
    font-size: 1.1em;
  }
  
  .font-header {
    font-size: 1.5em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .font-body {
    font-size: 1.3em;
  }
  
  .font-header {
    font-size: 2em;
  }
}

@media only screen and (min-width: 992px) {
  .font-body {
    font-size: 1.5em;
  }
  
  .font-header {
    font-size: 2em;
  }
}
