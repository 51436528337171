.main-contact {
    border-top: #345830 solid 2px;
}

.details {
    margin-left: 5%;
}

.contact-details {
    margin: 0 0 10%;
}

.detail-item {
    padding: 5% 0 0;
    font-size: 24px;
    color: #4b4b4b;
    line-height: 1.3;
}

.form {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.form_title {
    text-align: left;
    margin-bottom: 5%;
}

.title-font {
    font-size: 34px;
    color: #4b4b4b;
    line-height: 1.5;
    font-weight: 600;
}

.form input[type="email"],
.form input[type="text"],
.form input[type="tel"],
.form textarea {
    padding: 10px 7px;
    margin-bottom: 15px;
    outline: none;
    background-color: white;
}

.form textarea {
    resize: none;
}

.form .button {
    background: #244229;
    border: solid 1px #244229;
    color: white;
    cursor: pointer;
    padding: 10px 50px;
    text-align: center;
    text-transform: uppercase;
}

.form .button:hover {
    background: #345830;
    border: solid 1px #345830;
}

.form input[type="email"],
.form input[type="text"],
.form input[type="tel"],
.form textarea,
.form .button {
    font-size: 15px;
    border-radius: 3px
}

/* 

Responsive Navbar to switch between horizontal and vertical menus

*/

@media only screen and (max-width: 991px) {
    .main-contact {
        padding: 10% 5%;
    }
    .details {
        margin: 0 10%;
    }
}

@media only screen and (min-width: 992px) {
    .main-contact {
        padding: 10% 10%;
        display: flex;
    }

    .columns {
        flex: 50%;
    }
}
