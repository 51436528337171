.page-main {
    border-top: black solid 2px;
}

.banner {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 40vh;
}

.first-banner {
    background-image: url(../../images/excavation-page1.webp);
    background-position: 50% 50%;
}

.second-banner {
    background-image: url(../../images/excavation-page2.webp);
    background-position: 10% 100%;
}

.content {
    width: 80%;
    padding: 10% 0;
    margin: auto;
}

.header-content {
    margin: 5% 0;
}

.sub-content {
    margin: 5% 0;
}


.excavation-row {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    padding: 2% 0;
    gap: 10px;
}

.grid-img {
    flex: 100%;
    height: 40vh;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
}

.first-img {
    background-image: url(../../images/excavation_img1.webp);
    background-position: 10% 100%;
}

.second-img {
    background-image: url(../../images/excavation_img2.webp);
    background-position: 70% 10%;

}

.third-img {
    background-image: url(../../images/excavation_img3.webp);
    background-position: 10% 40%;
}

.fourth-img {
    background-image: url(../../images/excavation_img4.webp);
    background-position: 40% 100%;
}


/* 

Responsive CSS

*/
@media only screen  and (max-width: 400px) {
    .sub-content {
        margin: 12% 0;
    }
}

@media only screen  and (min-width: 400px) and (max-width: 767px) {
    .banner {
        min-height: 45vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner {
        min-height: 55vh;
    }

    .second-banner {
        background-position: 40% 100%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1800px) {
    .banner {
        min-height: 60vh;
    }

    .first-banner {
        background-position: 50% 80%;
    }

    .content {
        width: 65%;
        padding: 5% 0;
    }

    .grid-img {
        flex: 45%;
        height: 50vh;
    }

    .second-img {
        background-position: 45% 90%;
    }
}

@media only screen and (min-width: 1800px) {
    .banner {
        min-height: 70vh;
    }

    .first-banner {
        background-position: 50% 75%;
    }

    .third-banner {
        background-position: 10% 85%;
    }

    .content {
        width: 40%;
        padding: 4% 0;
    }

    .grid-img {
        flex: 45%;
        height: 70vh;
    }
}
