.topnav {
    min-width: 100vw;
    min-height: 80px;
    position: relative;
}

.vertical-img {
    padding: 10px 0 10px 25px;
    max-width: 30%;
    height: auto;
}

#myLinks {
    display: none;
    padding-left: 10px;
}

.topnav div, .dropdown-nav a {
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    display: block;
}

.topnav div.icon {
    display: block;
    right: 10%;
    margin: 0;
    position: absolute;
    top: 60%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.dropdown-nav {
    overflow: hidden;
    position: relative;
}


.dropdown-nav a:hover {
    background-color: #ddd;
}


