section {
    padding: 40px 0;
}

.landscaping-background {
    height: 380px;
    background-size: auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.landscaping-image0 {
    background-image: url(../../../assets/images/landscaping_design.webp);
}

.landscaping-image1 {
    background-image: url(../../../assets/images/landscaping_background2.webp);
}

.landscaping-text {
    position: relative;
}

.landscaping-text::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 100%;
    height: 125%;
    background: #C6B38E;
}

.landscaping-header, .landscaping-content {
    position: relative;
    z-index: 1;
    margin: 20px 15%;
}


/* 

Responsive CSS

*/

/*
 IOS specific CSS
*/
@supports ( -webkit-touch-callout: none ) {
    .landscaping-background {
        background-attachment: initial;
    }

    .conditional-background {
        visibility: hidden;
        height: 0;
    }
}